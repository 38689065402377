@keyframes hover-color {
  from {
    border-color: #e0e0e0;
  }
  to {
    border-color: #42A5F5;
  }
}

.magic-radio,
.magic-checkbox {
  position: absolute;
  display: none;
}

.magic-radio[disabled],
.magic-checkbox[disabled] {
  cursor: not-allowed;
}

.input-group-addon > .magic-radio + label, .input-group-addon > .magic-checkbox + label {
  display: block;
}

.magic-radio + label,
.magic-checkbox + label {
  position: relative;
  display: inline-block;
  cursor: pointer;
  vertical-align: middle;
  margin: 0;
}

.magic-radio + label:hover:before,
.magic-checkbox + label:hover:before {
  animation-duration: 0.4s;
  animation-fill-mode: both;
  animation-name: hover-color;
}

.magic-radio + label:before,
.magic-checkbox + label:before {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 16px;
  height: 16px;
  content: '';
  border: 1px solid #e0e0e0;
}

.magic-radio + label:after,
.magic-checkbox + label:after {
  position: absolute;
  display: none;
  content: '';
}

.magic-radio + label {
  min-width: 16px;
  min-height: 16px;
}

.magic-radio + label:not(:empty) {
  padding: 0 21px !important;
}

.magic-checkbox + label {
  min-width: 16px;
  min-height: 16px;
}

.magic-checkbox + label:not(:empty) {
  padding: 0 21px !important;
}

.btn-group .magic-radio + label, .btn-group .magic-checkbox + label {
  vertical-align: baseline;
  min-height: 1em;
}

.magic-radio[disabled] + label,
.magic-checkbox[disabled] + label {
  cursor: not-allowed;
  opacity: .5;
}

.magic-radio[disabled] + label:hover, .magic-radio[disabled] + label:before, .magic-radio[disabled] + label:after,
.magic-checkbox[disabled] + label:hover,
.magic-checkbox[disabled] + label:before,
.magic-checkbox[disabled] + label:after {
  cursor: not-allowed;
}

.magic-radio[disabled] + label:hover:before,
.magic-checkbox[disabled] + label:hover:before {
  animation-name: none;
}

.magic-radio:checked + label:before,
.magic-checkbox:checked + label:before {
  animation-name: none;
}

.magic-radio:checked + label:after,
.magic-checkbox:checked + label:after {
  display: block;
}

.magic-radio + label:before {
  border-radius: 50%;
  left: -.01em;
}

.magic-radio + label:after {
  top: 2.5px;
  left: 2.5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #42A5F5;
}

.magic-radio:checked + label:before {
  border: 1px solid #42A5F5;
}

.magic-checkbox + label:before {
  border-radius: 0;
}

.magic-checkbox + label:after {
  top: 1px;
  left: 5px;
  box-sizing: border-box;
  width: 6px;
  height: 12px;
  transform: rotate(45deg);
  border-width: 2px;
  border-style: solid;
  border-color: #fff;
  border-top: 0;
  border-left: 0;
}

.magic-checkbox:checked + label:before {
  border: #42A5F5;
  background: #42A5F5;
}
