
.login-bg {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
}

@media (min-width: 768px){
    .login-bg {
        display: block;
    }
}